import Icons from '../constants/Icons';

export const prefectures = [
  { label: '北海道', value: '01' },
  { label: '青森県', value: '02' },
  { label: '岩手県', value: '03' },
  { label: '宮城県', value: '04' },
  { label: '秋田県', value: '05' },
  { label: '山形県', value: '06' },
  { label: '福島県', value: '07' },
  { label: '茨城県', value: '08' },
  { label: '栃木県', value: '09' },
  { label: '群馬県', value: '10' },
  { label: '埼玉県', value: '11' },
  { label: '千葉県', value: '12' },
  { label: '東京都', value: '13' },
  { label: '神奈川県', value: '14' },
  { label: '新潟県', value: '15' },
  { label: '富山県', value: '16' },
  { label: '石川県', value: '17' },
  { label: '福井県', value: '18' },
  { label: '山梨県', value: '19' },
  { label: '長野県', value: '20' },
  { label: '岐阜県', value: '21' },
  { label: '静岡県', value: '22' },
  { label: '愛知県', value: '23' },
  { label: '三重県', value: '24' },
  { label: '滋賀県', value: '25' },
  { label: '京都府', value: '26' },
  { label: '大阪府', value: '27' },
  { label: '兵庫県', value: '28' },
  { label: '奈良県', value: '29' },
  { label: '和歌山県', value: '30' },
  { label: '鳥取県', value: '31' },
  { label: '島根県', value: '32' },
  { label: '岡山県', value: '33' },
  { label: '広島県', value: '34' },
  { label: '山口県', value: '35' },
  { label: '徳島県', value: '36' },
  { label: '香川県', value: '37' },
  { label: '愛媛県', value: '38' },
  { label: '高知県', value: '39' },
  { label: '福岡県', value: '40' },
  { label: '佐賀県', value: '41' },
  { label: '長崎県', value: '42' },
  { label: '熊本県', value: '43' },
  { label: '大分県', value: '44' },
  { label: '宮崎県', value: '45' },
  { label: '鹿児島県', value: '46' },
  { label: '沖縄県', value: '47' },
  { label: '海外', value: '99' },
];

export const genderOptions = [
  { label: '未選択', value: '0' },
  { label: '男性', value: '1' },
  { label: '女性', value: '2' },
];
export const genderOptions2 = [
  { label: 'すべて', value: '0' },
  { label: '男性', value: '1' },
  { label: '女性', value: '2' },
];

export const genderOptions3 = [
  { label: '未選択', value: '0' },
  { label: '女性', value: '2' },
  { label: '男性', value: '1' },
];

export const genderOptions4 = [
  { label: '未設定', value: '0' },
  { label: '男性', value: '1' },
  { label: '女性', value: '2' },
];
export const genderOptions5 = [
  { label: '未登録', value: '0' },
  { label: '男性', value: '1' },
  { label: '女性', value: '2' },
];

export const stampRuleOptions = [
  { label: '無制限', value: '0' },
  { label: '1QR1日1回', value: '1' },
  { label: '1日1回', value: '2' },
];

export const stampRallyRuleOptions = [
  { label: '無制限', value: '0' },
  { label: '1店舗1日1回', value: '1' },
  { label: '1日1回', value: '2' },
];

export const checkinRuleOptions = [
  { label: '無制限', value: '0' },
  { label: '1店舗1日1回', value: '1' },
  { label: '1日1回', value: '2' },
];

export const flagOptions = [
  { label: 'する', value: '1' },
  { label: 'しない', value: '0' },
];

export const flagOptions2 = [
  { label: '必須', value: '1' },
  { label: '任意', value: '0' },
];

export const flagOptions3 = [
  { label: '表示する', value: '1' },
  { label: '表示しない', value: '0' },
];

export const winningOptions = [
  { label: '本数', value: '0' },
  { label: '確率', value: '1' },
];

export const contentsTypeOptions = [
  { label: 'コンテンツ1', value: '1' },
  { label: 'コンテンツ2', value: '2' },
  { label: 'コンテンツ3', value: '3' },
  { label: 'コンテンツ4', value: '4' },
  { label: 'コンテンツ5', value: '5' },
  { label: 'コンテンツ6', value: '6' },
  { label: 'コンテンツ7', value: '7' },
  { label: 'コンテンツ8', value: '8' },
  { label: 'コンテンツ9', value: '9' },
  { label: 'コンテンツ10', value: '10' },
  { label: 'コンテンツ11', value: '11' },
  { label: 'コンテンツ12', value: '12' },
  { label: 'コンテンツ13', value: '13' },
];

export const couponTypeOptions = [
  { label: '通常', value: '0' },
  { label: 'スタンプ用', value: '1' },
  { label: 'アンケート用', value: '2' },
  { label: 'ガチャ用', value: '3' },
  { label: '抽選用', value: '4' },
  { label: '友達紹介用', value: '6' },
  { label: 'アドベントカレンダー用', value: '7' },
];

export const deliveryTargetOptions = {
  '01': '全ユーザ(配信後のダウンロード含む)',
  '02': '配信前のダウンロードユーザーのみ',
  '03': '配信後のダウンロードユーザーのみ',
  '04': 'IDアップロード',
};

export const targetIsOpenOptions = [
  { label: '開封者', value: '1' },
  { label: '未開封者', value: '0' },
];

export const stampTypeOptions = [
  { label: '台紙押印日', value: '0' },
  { label: '最終利用日', value: '1' },
];

export const stampDateTypeOptions = [
  { label: '台紙発行日', value: '0' },
  { label: '最新台紙失効日', value: '1' },
];

export const stampExchangeTypeOptions = [
  { label: 'ポイント', value: '1' },
  { label: 'クーポン', value: '2' },
];

export const gachaTriggerTypeOptions = [
  { label: '初回ログイン(スタンプ)', value: '0', gift_type: '2' },
  { label: 'アプリ起動(スタンプ)', value: '1', gift_type: '2' },
  { label: '来店チェックイン(スタンプ)', value: '2', gift_type: '2' },
  { label: '初回ログイン(ポイント)', value: '3', gift_type: '3' },
  { label: 'アプリ起動(ポイント)', value: '4', gift_type: '3' },
  { label: '来店チェックイン(ポイント)', value: '5', gift_type: '3' },
];

export const gachaStatusTypeOptions = [
  { label: 'ガチャ実施中', value: '0' },
  { label: '期間外', value: '1' },
  { label: '削除済み', value: '2' },
];

export const gachaResultStatusTypeOptions = [
  { label: '1等', value: '1' },
  { label: '2等', value: '2' },
  { label: '3等', value: '3' },
  { label: 'はずれ', value: '9' },
];

export const gachaGiftTypeOptions = [
  { label: 'スタンプ', value: '2' },
  { label: 'ポイント', value: '3' },
];

export const answerTypeOptions = [
  { label: '単数選択', value: '01' },
  { label: '複数選択', value: '02' },
  { label: 'フリー回答（短文）', value: '03' },
  { label: 'フリー回答（長文）', value: '04' },
];

export const graphTypeOptions = [
  { label: '円グラフ', value: '00' },
  { label: '棒グラフ（縦棒）', value: '01' },
  { label: '棒グラフ（横棒）', value: '02' },
];

export const summaryTypeOptions = [
  { label: 'All', value: '00' },
  { label: '年代', value: '01' },
  { label: '性別', value: '02' },
  { label: '所在地', value: '03' },
  { label: 'お気に入り店舗', value: '04' },
];

export const stampRallyTriggerTypeOptions = [
  { label: '初回ログイン', value: '0' },
  { label: 'アプリ起動', value: '1' },
  { label: 'チェックイン', value: '2' },
];

export const couponTypes = {
  common: 0,
  stamp: 1,
  enquete: 2,
  gacha: 3,
  lottery: 4,
  adventCalendar: 7,
};

export const ageTypes = [
  { label: '未登録', value: '00' },
  { label: '10代未満', value: '01' },
  { label: '10代', value: '10' },
  { label: '20代', value: '20' },
  { label: '30代', value: '30' },
  { label: '40代', value: '40' },
  { label: '50代', value: '50' },
  { label: '60代', value: '60' },
  { label: '70代以上', value: '70' },
];

export const summaryFlagTypes = {
  summary_sex_flag: '性別',
  summary_age_flag: '年代',
  summary_address_flag: '所在地',
  summary_favorite_shop_flag: 'お気に入り店舗',
};

export const csvFavoriteItemTypes = {
  coupon_result: '0',
  user_coupon_result: '1',
  user_stamp: '2',
  stamp_result: '3',
  user: '4',
  enquete: '5',
  gacha_applicant: '6',
  gacha: '7',
  stamp_rally_user_result: '10',
  stamp_rally_result: '11',
  point_exchange_result: '12',
  lottery_applicant: '13',
  reserve: '16',
  advent_calendar: '17',
  top_image: "18",
  contents: "19",
};

export const csvDownloadTypes = {
  download: '0',
  active_user: '1',
  coupon_result: '2',
  user_coupon_result: '3',
  user_stamp: '4',
  stamp: '5',
  user: '6',
  enquete: '7',
  gacha_applicant: '8',
  gacha: '9',
  stamp_rally_user_result: '10',
  stamp_rally_result: '11',
  point_exchange_result: '12',
  lottery_applicant: '13',
  reserve: '16',
  advent_calendar: '17',
};

export const displayOrderOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
  { label: '31', value: '31' },
  { label: '32', value: '32' },
  { label: '33', value: '33' },
  { label: '34', value: '34' },
  { label: '35', value: '35' },
  { label: '36', value: '36' },
  { label: '37', value: '37' },
  { label: '38', value: '38' },
  { label: '39', value: '39' },
  { label: '40', value: '40' },
  { label: '41', value: '41' },
  { label: '42', value: '42' },
  { label: '43', value: '43' },
  { label: '44', value: '44' },
  { label: '45', value: '45' },
  { label: '46', value: '46' },
  { label: '47', value: '47' },
  { label: '48', value: '48' },
  { label: '49', value: '49' },
  { label: '50', value: '50' },
  { label: '51', value: '51' },
  { label: '52', value: '52' },
  { label: '53', value: '53' },
  { label: '54', value: '54' },
  { label: '55', value: '55' },
  { label: '56', value: '56' },
  { label: '57', value: '57' },
  { label: '58', value: '58' },
  { label: '59', value: '59' },
  { label: '60', value: '60' },
  { label: '61', value: '61' },
  { label: '62', value: '62' },
  { label: '63', value: '63' },
  { label: '64', value: '64' },
  { label: '65', value: '65' },
  { label: '66', value: '66' },
  { label: '67', value: '67' },
  { label: '68', value: '68' },
  { label: '69', value: '69' },
  { label: '70', value: '70' },
  { label: '71', value: '71' },
  { label: '72', value: '72' },
  { label: '73', value: '73' },
  { label: '74', value: '74' },
  { label: '75', value: '75' },
  { label: '76', value: '76' },
  { label: '77', value: '77' },
  { label: '78', value: '78' },
  { label: '79', value: '79' },
  { label: '80', value: '80' },
  { label: '81', value: '81' },
  { label: '82', value: '82' },
  { label: '83', value: '83' },
  { label: '84', value: '84' },
  { label: '85', value: '85' },
  { label: '86', value: '86' },
  { label: '87', value: '87' },
  { label: '88', value: '88' },
  { label: '89', value: '89' },
  { label: '90', value: '90' },
  { label: '91', value: '91' },
  { label: '92', value: '92' },
  { label: '93', value: '93' },
  { label: '94', value: '94' },
  { label: '95', value: '95' },
  { label: '96', value: '96' },
  { label: '97', value: '97' },
  { label: '98', value: '98' },
  { label: '99', value: '99' },
  { label: '100', value: '100' },
];

export const monthOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
];

export const hourOptions = [
  { label: '00', value: '00' },
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
];

export const reserveFavoriteItemOptions = [
  { label: '会員番号', value: 'user_code' },
  { label: '端末ID', value: 'device_id' },
];

export const couponResultFavoriteItemOptions = [
  { label: 'クーポンCD', value: 'coupon_code' },
  { label: 'クーポン名', value: 'coupon_name' },
  { label: 'クーポン種別', value: 'coupon_type' },
  { label: '台紙CD', value: 'couponcard_code' },
  { label: '台紙名', value: 'couponcard_name' },
  { label: '配信日', value: 'delivery_date' },
  { label: '配信数', value: 'delivery_count' },
  { label: '開封数', value: 'open_count' },
  { label: '利用数', value: 'used_count' },
  { label: '対象ブランドCD', value: 'brand_code' },
  { label: '対象ブランド名', value: 'brand_name' },
  { label: '対象店舗CD', value: 'shop_code' },
  { label: '対象店舗名', value: 'shop_name' },
];

export const couponUserResultFavoriteItemOptions = [
  { label: '会員番号', value: 'user_code' },
  { label: 'クーポンCD', value: 'coupon_code' },
  { label: 'クーポン名', value: 'coupon_name' },
  { label: 'クーポン種別', value: 'coupon_type' },
  { label: '台紙CD', value: 'couponcard_code' },
  { label: '台紙名', value: 'couponcard_name' },
  { label: '配信日', value: 'delivery_date' },
  { label: '開封日', value: 'open_date' },
  { label: '利用ステータス', value: 'coupon_status' },
  { label: '利用状況', value: 'coupon_used_flg' },
  { label: '残利用回数', value: 'coupon_available_count' },
  { label: '利用日', value: 'used_date' },
  { label: '失効日', value: 'expire_date' },
  { label: '対象ブランドCD', value: 'brand_code' },
  { label: '対象ブランド名', value: 'brand_name' },
  { label: '対象店舗CD', value: 'shop_code' },
  { label: '対象店舗名', value: 'shop_name' },
];

export const mountUserResultFavoriteItemOptions = [
  { label: '会員番号', value: 'user_code' },
  { label: '台紙CD', value: 'stamp_code' },
  { label: '台紙名', value: 'stamp_name' },
  { label: '台紙n枚目', value: 'stamp_n_page' },
  { label: '台紙内スタンプ数', value: 'stamp_count' },
  { label: '最新台紙失効日', value: 'expire_date' },
  { label: '最新台紙初回押印日', value: 'first_stamp_date' },
  { label: '最新台紙最終押印日', value: 'last_stamp_date' },
  { label: '対象ブランドCD', value: 'brand_code' },
  { label: '対象ブランド名', value: 'brand_name' },
  { label: '対象店舗CD', value: 'shop_code' },
  { label: '対象店舗名', value: 'shop_name' },
];

export const stampedDetailFavoriteItemOptions = [
  { label: '会員番号', value: 'user_code' },
  { label: '台紙CD', value: 'stamp_code' },
  { label: '台紙名', value: 'stamp_name' },
  { label: '押印QRコード', value: 'qr_code' },
  { label: '押印QR名', value: 'qr_name' },
  { label: '押印数', value: 'stamp_count' },
  { label: '台紙n枚目', value: 'stamp_n_page' },
  { label: '押印日', value: 'stamp_date' },
  { label: '台紙1枚目初回押印FLG', value: 'first_stamped_flag' },
  { label: '押印ブランドCD', value: 'brand_code' },
  { label: '押印ブランド名', value: 'brand_name' },
  { label: '押印店舗CD', value: 'shop_code' },
  { label: '押印店舗名', value: 'shop_name' },
];

export const stampRallyResultFavoriteItemOptions = [
  { label: '会員番号', value: 'user_code' },
  { label: '台紙CD', value: 'stamp_rally_code' },
  { label: '台紙名', value: 'stamp_rally_name' },
  { label: '押印数', value: 'stamp_rally_count' },
  { label: '台紙n枚目', value: 'stamp_rally_n_page' },
  { label: '押印日', value: 'stamp_rally_date' },
  { label: '台紙1枚目初回押印FLG', value: 'first_stamp_rally_flag' },
  { label: 'トリガー', value: 'trigger' },
];

export const stampRallyUserResultFavoriteItemOptions = [
  { label: '会員番号', value: 'user_code' },
  { label: '台紙CD', value: 'stamp_rally_code' },
  { label: '台紙名', value: 'stamp_rally_name' },
  { label: '台紙n枚目', value: 'stamp_rally_n_page' },
  { label: '台紙内スタンプ数', value: 'stamp_rally_count' },
  { label: '最新台紙失効日', value: 'expire_date' },
  { label: '最新台紙初回押印日', value: 'first_stamp_rally_date' },
  { label: '最新台紙最終押印日', value: 'last_stamp_rally_date' },
];

export const pointExchangeResultFavoriteItemOptions = [
  { label: '会員番号', value: 'user_code' },
  { label: '台紙CD', value: 'stamp_rally_code' },
  { label: '台紙名', value: 'stamp_rally_name' },
  { label: 'ポイント数', value: 'point' },
  { label: '台紙n枚目', value: 'stamp_rally_n_page' },
];

export const userFavoriteItemOptions = [
  { label: '端末ID', value: 'device_id' },
  { label: '会員番号', value: 'user_code' },
  { label: '生年月日', value: 'birthday' },
  { label: '都道府県', value: 'prefectures' },
  { label: '性別', value: 'gender' },
  { label: 'お気に入り店舗', value: 'favorite_shop' },
  { label: 'ダウンロード日', value: 'create_at' },
  { label: '更新日', value: 'update_at' },
];

export const enqueteFavoriteItemOptions = [
  { label: '端末ID', value: 'device_id' },
  { label: '会員番号', value: 'user_code' },
  { label: '回答数', value: 'summary_count' },
  { label: 'アンケートカテゴリ', value: 'category_name' },
  { label: 'アンケートCD', value: 'enquete_code' },
  { label: 'アンケート名', value: 'enquete_name' },
  { label: '定型文', value: 'enquete_template' },
  { label: 'アンケート開始日', value: 'public_start_date' },
  { label: 'アンケート終了日', value: 'public_end_date' },
  { label: 'クーポンCD', value: 'coupon_code' },
  { label: 'クーポン名', value: 'coupon_name' },
  { label: 'プッシュ通知', value: 'notice_flag' },
  { label: '設問', value: 'question_name' },
  { label: '回答必須', value: 'required_flag' },
  { label: '回答区分', value: 'answer_type' },
  { label: '回答', value: 'answer_text' },
  { label: '回答結果表示方法', value: 'answer_result_display_flag' },
  { label: 'グラフタイプ', value: 'graph_type' },
  { label: '性別', value: 'sex' },
  { label: '年代', value: 'age' },
  { label: '所在地', value: 'prefectures' },
  { label: 'お気に入り店舗', value: 'favorite_shop_name' },
];

export const lotteryApplicantFavoriteItemOptions = [
  { label: '抽選CD', value: 'lottery_code' },
  { label: '会員番号', value: 'user_code' },
  { label: '景品受取日時', value: 'coupon_used_date' },
  { label: '抽選名', value: 'lottery_name' },
  { label: 'ステータス', value: 'status' },
  { label: '応募日時', value: 'entry_date' },
  { label: '当選景品', value: 'coupon_code' },
];

export const gachaFavoriteItemOptions = [
  { label: 'ガチャCD', value: 'gacha_code' },
  { label: 'ガチャ名', value: 'gacha_name' },
  { label: 'トリガー', value: 'trigger' },
  { label: 'キャンペーン表示開始日', value: 'campaign_start_date' },
  { label: 'キャンペーン表示終了日', value: 'campaign_end_date' },
  { label: 'ガチャ実施開始日', value: 'gacha_start_date' },
  { label: 'ガチャ実施終了日', value: 'gacha_end_date' },
  { label: 'ステータス', value: 'status' },
  { label: '登録日', value: 'create_at' },
  { label: '参加人数', value: 'applicant_count' },
  { label: 'グループ', value: 'group_name' },
  { label: '設定当選数', value: 'max_winner_count' },
  { label: '当選数', value: 'winner_count' },
  { label: '残り本数', value: 'remaining_count' },
  { label: 'スタンプ数', value: 'stamp_count' },
  { label: 'ポイント数', value: 'point' },
];

export const gachaApplicantFavoriteItemOptions = [
  { label: 'ガチャCD', value: 'gacha_code' },
  { label: 'ガチャ名', value: 'gacha_name' },
  { label: 'トリガー', value: 'trigger' },
  { label: '店舗', value: 'location_name' },
  { label: '会員番号', value: 'user_code' },
  { label: '参加日', value: 'applicant_date' },
  { label: 'ステータス', value: 'status' },
  { label: '当選景品種別', value: 'gift_type' },
  { label: '当選景品', value: 'gift' },
];

export const adventCalendarFavoriteItemOptions = [
  { label: 'アドベントカレンダーCD', value: 'advent_calendar_code' },
  { label: '登録日', value: 'create_at' },
  { label: 'アドベントカレンダー名', value: 'advent_calendar_name' },
  { label: '参加人数', value: 'total_join_count' },
  { label: 'キャンペーン表示開始日', value: 'display_start_date' },
  { label: 'アドベントカレンダー実施日', value: 'advent_calendar_date' },
  { label: 'キャンペーン表示終了日', value: 'display_end_date' },
  { label: '会員番号', value: 'user_code' },
  { label: 'ステータス', value: 'status' },
  { label: '端末ID', value: 'device_id' },
];

export const menuOptions = [
  {
    label: 'トップ',
    name: 'top_flag',
    image: Icons.IconInfo,
    link: '/top',
    screen_code: "000000001"
  },
  {
    label: 'コンテンツ',
    name: 'content_flag',
    image: Icons.IconContents,
    subMenu: [
      {
        label: 'トップ画像',
        name: 'top_image_flag',
        link: '/topImage',
        screen_code: "000000002"
      },
      {
        label: 'コンテンツ',
        name: 'top_content_flag',
        link: '/contents',
        screen_code: "000000003"
      },
    ],
  },
  {
    label: 'プッシュ通知',
    name: 'notice_flag',
    image: Icons.IconBell,
    subMenu: [
      {
        label: '新規登録・配信設定',
        name: 'notice_list_flag',
        link: '/notification',
        screen_code: "000000004"
      },
      {
        label: 'ジオプッシュ登録・配信設定',
        name: 'notice_geopush_list_flag',
        link: '/geopush',
        screen_code: "000000005"
      },
      {
        label: '予約・配信済み一覧',
        name: 'notice_reserve_list_flag',
        link: '/reserve',
        screen_code: "000000006"
      },
    ],
  },
  {
    label: 'クーポン',
    name: 'coupon_flag',
    image: Icons.IconCoupon,
    subMenu: [
      {
        label: '新規登録・クーポン一覧',
        name: 'coupon_list_flag',
        link: '/coupon',
        screen_code: "000000007"
      },
      {
        label: 'クーポン単位実績一覧',
        name: 'coupon_result_flag',
        link: '/couponResult',
        screen_code: "000000008"
      },
      {
        label: 'ユーザー×クーポン単位実績一覧',
        name: 'coupon_user_result_flag',
        link: '/couponUserResult',
        screen_code: "000000009"
      },
    ],
  },
  {
    label: 'スタンプ',
    name: 'stamp_flag',
    image: Icons.IconStamp,
    subMenu: [
      {
        label: '新規登録・台紙一覧',
        name: 'stamp_card_flag',
        link: '/stamp',
        screen_code: "000000010"
      },
      {
        label: '新規登録・QR一覧',
        name: 'stamp_qr_flag',
        link: '/qr',
        screen_code: "000000011"
      },
      {
        label: 'ユーザー×台紙単位実績一覧',
        name: 'stamp_user_result_flag',
        link: '/mountUserResult',
        screen_code: "000000012"
      },
      {
        label: '押印実績明細一覧',
        name: 'stamp_result_flag',
        link: '/stampedDetail',
        screen_code: "000000013"
      },
    ],
  },
  {
    // To:Do name変更
    label: 'スタンプラリー',
    name: 'stamp_rally_flag',
    image: Icons.IconStampRally,
    subMenu: [
      {
        label: '新規登録・台紙一覧',
        name: 'stamp_rally_card_flag',
        link: '/stampRally',
        screen_code: "000000014"
      },
      {
        label: 'ユーザー×台紙単位実績一覧',
        name: 'stamp_rally_user_result_flag',
        link: '/stampRallyUserResult',
        screen_code: "000000015"
      },
      {
        label: '押印実績明細一覧',
        name: 'stamp_rally_result_flag',
        link: '/stampRallyResult',
        screen_code: "000000016"
      },
      {
        label: 'ポイント交換実績一覧',
        name: 'point_exchange_result_flag',
        link: '/pointExchangeResult',
        screen_code: "000000017"
      },
    ],
  },
  {
    label: 'ガチャ',
    name: 'gacha_flag',
    image: Icons.IconGatya,
    subMenu: [
      {
        label: '新規登録・ガチャ一覧',
        name: 'gacha_list_flag',
        link: '/gacha',
        screen_code: "000000018"
      },
      {
        label: 'ガチャ参加者一覧',
        name: 'gacha_applicant_list_flag',
        link: '/gachaApplicant',
        screen_code: "000000019"
      },
    ],
  },
  {
    label: 'アンケート',
    name: 'enquete_flag',
    image: Icons.IconEnquete,
    subMenu: [
      {
        label: '新規登録・アンケート一覧',
        name: 'enquete_list_flag',
        link: '/enquete',
        screen_code: "000000020"
      },
    ],
  },
  {
    label: '抽選',
    name: 'lottery_flag',
    image: Icons.IconLottery,
    subMenu: [
      {
        label: '新規登録・抽選一覧',
        name: 'lottery_list_flag',
        link: '/lottery',
        screen_code: "000000021"
      },
      {
        label: '応募者・当選者一覧',
        name: 'lottery_applicant_list_flag',
        link: '/lotteryApplicant',
        screen_code: "000000022"
      },
    ],
  },
  {
    label: "アドベントカレンダー",
    name: 'advent_calendar_flag',
    image: Icons.IconAdventCalendar,
    subMenu: [
      {
        label: (
          <div className={"advent-calendar-submenu"}>
            <span className={"advent-calendar-submenu-text"}>
              新規登録・アドベントカレンダー一覧
            </span>
          </div>
        ),
        name: 'advent_calendar_list_flag',
        link: '/adventCalendar',
        screen_code: "000000035"
      },
    ],
  },
  {
    label: 'LINE',
    name: 'line_flag',
    image: Icons.IconLine,
    subMenu: [
      {
        label: '新規登録・リッチメニュー一覧',
        name: 'line_richmenu_list_flag',
        link: '/lineRichmenu',
        screen_code: "000000023"
      },
      {
        label: 'LINE公式アカウント管理',
        name: 'line_account_list_flag',
        link: '/lineAccount',
        screen_code: "000000024"
      },
    ],
  },
  {
    label: '分析',
    name: 'analysis_flag',
    image: Icons.IconBarChart,
    subMenu: [
      {
        label: 'ダウンロード数',
        name: 'analysis_download_flag',
        link: '/download',
        screen_code: "000000025"
      },
      {
        label: 'アクティブユーザー数',
        name: 'analysis_active_user_flag',
        link: '/activeUser',
        screen_code: "000000026"
      },
      {
        label: '反応マップ',
        name: 'analysis_reaction_map_flag',
        link: '/heatmap',
        screen_code: "000000027"
      },
    ],
  },
  {
    label: 'マスタ',
    name: 'mst_flag',
    image: Icons.IconDatabase,
    subMenu: [
      {
        label: 'ユーザー管理',
        name: 'user_mst_flag',
        link: '/userManagement',
        screen_code: "000000028"
      },
      {
        label: 'ブランド管理',
        name: 'brand_mst_flag',
        link: '/brandManagement',
        screen_code: "000000029"
      },
      {
        label: '店舗管理',
        name: 'shop_mst_flag',
        link: '/shopManagement',
        screen_code: "000000030"
      },
      {
        label: '社員管理',
        name: 'staff_mst_flag',
        link: '/staffManagement',
        screen_code: "000000031"
      },
    ],
  },
  {
    label: '設定',
    name: 'settings_flag',
    image: Icons.IconSettings,
    subMenu: [
      {
        label: 'カテゴリ管理',
        name: 'category_flag',
        link: '/category',
        screen_code: "000000032"
      },
      {
        label: '定型文テンプレート管理',
        name: 'template_flag',
        link: '/template',
        screen_code: "000000033"
      },
      {
        label: '名称設定',
        name: 'name_flag',
        link: '/name',
        screen_code: "000000034"
      },
    ],
  },
];

export const sendTypeOptions = [
  { label: '予約配信', value: '1' },
  { label: '即時配信', value: '2' },
];

export const sendMethodTypeOptions = [
  { label: '通常(TENCO)', value: '1' },
  { label: '自動(TENCO)', value: '2' },
  { label: '連携', value: '3' },
];

export const repeatTypeOptions = [
  { label: '繰り返さない', value: '0' },
  { label: '繰り返す', value: '1' },
];

export const repeatPeriodOptions = [
  { label: '日', value: 'day' },
  { label: '毎週', value: 'week' },
  { label: '毎月', value: 'month' },
];

export const condOptions = [
  { label: '条件なし', value: '0' },
  { label: '条件あり', value: '1' },
  { label: 'ID指定', value: '2' },
];

export const staffAuthOptions = [
  { label: 'システム管理者', value: '0' },
  { label: '管理責任者', value: '1' },
  { label: 'ブランド責任者', value: '2' },
  { label: '店舗責任者', value: '3' },
  { label: 'スタッフ', value: '4' },
];

export const sendStatusOptions = [
  { label: '予約済み', value: '1' },
  { label: '抽出済み', value: '2' },
  { label: '配信準備', value: '3' },
  { label: '配信中', value: '4' },
  { label: '配信完了', value: '5' },
  { label: '抽出エラー', value: '6' },
  { label: '配信エラー', value: '7' },
  { label: 'キャンセル', value: '8' },
];

export const canUpdateSendStatus = ['0', '1', '2', '6'];

// sendStatusがnull、0：下書き、1：予約済み、2：抽出済み、5：配信完了、6：抽出エラーの場合、記事の編集が可能
export const canUpdateSendStatusOnlyNotificationPage = [null, '0', '1', '2', '5', '6'];

export const appDisplayOptions = [
  { label: '表示', value: 1 },
  { label: '非表示', value: 0 },
];

export const exchangeType = [
  { label: 'ポイント', value: '1' },
  { label: 'クーポン', value: '2' },
];

export const linkDisplayOptions = [
  { label: 'WEBビュー', value: '0' },
  { label: 'In App Browsers', value: '1' },
];

export const pageAccessCsvDownloadModeOptions = [
  { label: '詳細ページ閲覧回数', value: '0' },
  { label: '詳細ページ閲覧者数', value: '1' },
  { label: '外部リンク先閲覧回数', value: '2' },
  { label: '外部リンク先閲覧者数', value: '3' },
];

export const pageAccessCsvFavoriteItemOptions = [
  { label: '閲覧日時', value: 'created_at' },
  { label: '会員番号', value: 'user_code' },
];
export const categoryTypeOptions = [
  { label: 'ブランド管理', value: '1' },
  { label: '店舗管理', value: '2' },
  { label: 'アンケート', value: '3' },
];
export const giftOptions = [
  { label: '景品あり', value: true },
  { label: '景品なし', value: false },
];

export const richmenuDisplayOptions = [
  { label: 'オープン', value: 1 },
  { label: 'クローズ', value: 0 },
];

export const richmenuTabOptions = [
  { label: '1タブ', value: 1 },
  { label: '2タブ', value: 2 },
];

export const richmenuActionTypeOptions = [
  { label: 'URL', value: 'url' },
  { label: 'TEL', value: 'tel' },
  { label: 'ユーザーメッセージ', value: 'message' },
  { label: '設定しない', value: 'none' },
  { label: 'タブ切り替え', value: 'richmenuswitch' },
];

export const scenarioOptions = [
  { label: '前', value: '0' },
  { label: '以上前', value: '1' },
  { label: '経過', value: '2' },
  { label: '以上経過', value: '3' },
];

export const templateOptions = [
  { label: 'クーポン', value: '0' },
  { label: 'アンケート', value: '1' },
  { label: 'スタンプ', value: '2' },
  { label: 'スタンプラリー', value: '3' },
  { label: 'ガチャ', value: '4' },
  { label: '抽選', value: '5' },
  { label: 'アドベントカレンダー', value: '6' },
];
