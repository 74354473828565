import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import ModalTitle from '../modalTitle';
import { gachaActions } from '../../slices/gachaSlice';
import {
  convertImageFieldName,
  getOptionLabel,
  selectedShopListStr,
} from '../../utils/fnUtil';
import { flagOptions, winningOptions } from '../../constants/options';
import moment from 'moment';
import TcButton from '../tcButton';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import VideoImageTagElement from '../videoImageTagElement';
import ImagesReference from '../imagesReference';

const GachaReferenceModal = () => {
  const dispatch = useDispatch();
  const { referenceGacha, initialValues } = useSelector(state => state.gacha);

  const { selectOptionList } = useSelector(state => state.name);

  const {
    accountInfo: {
      new_contents_flag,
      add_article_data: { video_upload_flag },
    },
  } = useSelector(state => state.account);

  const {
    gacha_code,
    gacha_name,
    trigger,
    consecutive_startup_days,
    banner_image_url,
    gacha_content,
    gacha_rich_content,
    image_url,
    fixed_phrase,
    campaign_start_date,
    campaign_end_date,
    gacha_start_date,
    gacha_end_date,
    campaign_notice_flag,
    gacha_notice_flag,
    lottery_method,
    stamp_rally_code,
    stamp_rally_name,
    check_in_rule,
    location_gift_list,
  } = referenceGacha || initialValues;

  const onBack = () => dispatch(gachaActions.closeReferenceModal());

  const dateToString = date => (
    <div className="font-sm">
      <span>{moment(date)?.format('YYYY/M/D HH:mm')}</span>
    </div>
  );

  const selectedShopListStr = idx => {
    const { locations } = location_gift_list[idx];
    if (locations.length === 0) {
      return '-';
    }
    return locations.map(shop => shop.location_name).join(',');
  };

  const isStamp = ['0', '1', '2'].includes(trigger);
  const isProbability = lottery_method === '1';

  // 来店チェックイン
  const isCheckIn = ['2', '5'].includes(trigger);

  return (
    <Modal
      className={'gacha-reference-modal tc-modal'}
      open={!!referenceGacha}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title="ガチャ参照" />
        <div className="input-area">
          <div className="reference-area">
            <span className="pre-label">ガチャCD</span>
            <span className="font-sm">{gacha_code}</span>
          </div>
          <div className="reference-area">
            <span className="pre-label">トリガー</span>
            <span className="font-sm">
              {getOptionLabel(
                selectOptionList?.filter(s => s.field_name === 'トリガー')?.[0]
                  ?.select_option ?? [],
                trigger
              )}
            </span>
          </div>
          {!!Number(trigger) && (
            <div className="reference-area">
              <span className="pre-label">連続起動日数</span>
              <span className="font-sm">{consecutive_startup_days}</span>
              <span className="space-left-small">日</span>
            </div>
          )}
          <div className="image-area">
            <span className="pre-label">
              {convertImageFieldName(video_upload_flag, 'バナー画像')}
            </span>
            <div className="image-select">
              {!!banner_image_url && (
                <VideoImageTagElement
                  imageUrl={banner_image_url}
                  height={150}
                />
              )}
            </div>
          </div>
          <div className="reference-area">
            <span className="pre-label">ガチャ名</span>
            <span className="font-sm">{gacha_name}</span>
          </div>
          <div className="reference-area" style={{ alignItems: 'flex-start' }}>
            <span className="pre-label">ガチャ説明</span>
            {new_contents_flag !== 0 ? (
              <div className="text-area" style={{ flexGrow: 1 }}>
                <span className="editor-detail">
                  <RichTextEditor
                    readOnly={true}
                    editorState={toSlateState(gacha_rich_content)}
                  />
                </span>
              </div>
            ) : (
              <span style={{ whiteSpace: 'pre-wrap' }}>{gacha_content}</span>
            )}
          </div>
          <div className="reference-image-area">
            <label className="text-required">画像</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
              }}
            >
              <ImagesReference
                image_url={image_url}
                from={0}
                to={4}
                width={150}
                height={150}
              />
              <ImagesReference
                image_url={image_url}
                from={5}
                to={9}
                width={150}
                height={150}
              />
            </div>
          </div>
          <div className="reference-area">
            <span className="pre-label">定型文</span>
            <span className="font-sm">
              {!!fixed_phrase ? fixed_phrase : '-'}
            </span>
          </div>
          <div className="reference-area">
            <span className="pre-label">キャンペーン表示期間</span>
            {dateToString(campaign_start_date)}
            <span className="space">〜</span>
            {dateToString(campaign_end_date)}
          </div>
          <div className="reference-area">
            <span className="pre-label">ガチャ実施期間</span>
            {dateToString(gacha_start_date)}
            <span className="space">〜</span>
            {dateToString(gacha_end_date)}
          </div>
          <span className="push-area">
            <span className="pre-label push-label">プッシュ通知</span>
            <div className="push-list">
              <div className="radio-area">
                <span className="pre-label">キャンペーン表示開始</span>
                <span className="font-sm">
                  {getOptionLabel(flagOptions, campaign_notice_flag)}
                </span>
              </div>
              <div className="radio-area end">
                <span className="pre-label">ガチャ実施開始</span>
                <span className="font-sm">
                  {getOptionLabel(flagOptions, gacha_notice_flag)}
                </span>
              </div>
            </div>
          </span>
          <div className="reference-area type">
            <span className="pre-label ">当選形式</span>
            <span className="font-sm" style={{ fontSize: '12px' }}>
              {getOptionLabel(winningOptions, lottery_method)}
            </span>
          </div>

          <div className="active-stamp-area">
            <div className="label">スタンプカード</div>
            <div className="item-area">
              <div className="item">
                {`${stamp_rally_code ?? '-'}：${stamp_rally_name ?? '-'}`}
              </div>
            </div>
          </div>

          {check_in_rule ? (
            <div className="active-stamp-area">
              <div className="label">チェックインルール</div>
              <div className="item-area">
                <div className="item">
                  {check_in_rule === '0'
                    ? '無制限'
                    : check_in_rule === '1'
                    ? '1店舗1日1回'
                    : check_in_rule === '2'
                    ? '1日1回'
                    : '-'}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={'gacha-shop_gift_list-reference-card-area'}>
            {location_gift_list?.map((location, idx) => (
              <div
                className={'gacha-shop_gift_list-reference-card'}
                key={`gacha-shop_gift_list-reference-card-${idx}`}
              >
                {isCheckIn && (
                  <div className={'gacha-shop_gift_list-header'}>
                    <div className={'gacha-shop_gift_list-header-label'}>
                      対象店舗
                    </div>
                    <div className={'gacha-shop_gift_list-header-shops'}>
                      {selectedShopListStr(idx)}
                    </div>
                  </div>
                )}
                <span
                  className="gift-area"
                  style={!isCheckIn ? { marginTop: 0 } : {}}
                >
                  <span className="gacha-pre-label gift-label">景品</span>
                  <div className="gift-list">
                    {location?.gift_list.map((v, i) => (
                      <>
                        <div className="bold">{`${
                          v.rank === '9' ? 'はずれ' : v.rank + '等'
                        }`}</div>
                        <div className="gift-input-area">
                          <span className="pre-label">
                            {isStamp ? 'スタンプ' : 'ポイント'}
                          </span>
                          <span className="font-sm">
                            {isStamp
                              ? `${v?.stamp_count?.toLocaleString()}　個`
                              : `${v?.point?.toLocaleString()}　pt`}
                          </span>
                        </div>
                        {v.rank === '9' &&
                        (v?.winner_probability === 0 ||
                          v?.max_winner_count === null) ? (
                          <></>
                        ) : (
                          <div
                            className={
                              i < location.gift_list.length - 1 && 'not-end'
                            }
                          >
                            <div className="gift-input-area">
                              <span className="pre-label">
                                {isProbability ? '確率' : '本数'}
                              </span>
                              <span className="font-sm">
                                {isProbability
                                  ? `${v?.winner_probability + '　%'}`
                                  : `${v?.max_winner_count}　本`}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                    {!location?.gift_list.some(gift => gift.rank === '9') && (
                      <>
                        <div className="bold" style={{ marginTop: 30 }}>
                          はずれ
                        </div>
                        <div className="no-gift" style={{ marginTop: 30 }}>
                          景品なし
                        </div>
                      </>
                    )}
                  </div>
                </span>
              </div>
            ))}
          </div>

          <div className="button-container">
            <TcButton text="閉じる" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GachaReferenceModal;
