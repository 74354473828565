import TextBox from '../components/util/textBox';
import { dateFormat, thousandsSeparator } from '../utils/fnUtil';
import {
  prefectures,
  genderOptions,
  genderOptions4,
  staffAuthOptions,
  repeatTypeOptions,
  condOptions,
  sendStatusOptions,
  gachaTriggerTypeOptions,
  gachaStatusTypeOptions,
  gachaResultStatusTypeOptions,
  gachaGiftTypeOptions,
  sendMethodTypeOptions,
  stampRallyTriggerTypeOptions, templateOptions,
} from './options';

export const brandManagementcolumns = [
  {
    title: 'ブランドCD',
    sortField: 'brand_code',
  },
  {
    title: 'ブランド名',
    sortField: 'brand_name',
  },
  {
    title: 'ブランド名(カナ)',
    sortField: 'brand_name_kana',
  },
  {
    title: 'ブランドカテゴリ',
    sortField: 'category_name',
  },
  {
    title: '表示優先順',
    sortField: 'display_order',
    render: value => value || '-',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      ({
        0: '使用中',
        1: '使用不可',
        2: '削除済み',
      }[status]),
  },
];

export const shopManagementcolumns = [
  {
    title: '店舗CD',
    sortField: 'shop_code',
  },
  {
    title: '店舗名',
    sortField: 'shop_name',
  },
  {
    title: 'ブランド',
    sortField: 'brand_name',
  },
  {
    title: '店舗カテゴリ',
    sortField: 'category_name',
  },
  {
    title: '都道府県',
    sortField: 'prefectures',
    render: prefectureCode =>
      prefectures.find(p => p.value === prefectureCode)?.label,
  },
  {
    title: '電話番号',
    sortField: 'tel',
  },
  {
    title: '表示優先順',
    sortField: 'display_order',
    render: value => value || '-',
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      ({
        0: '使用中',
        1: '使用不可',
        2: '削除済み',
      }[status]),
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const staffManagementcolumns = [
  {
    title: '社員CD',
    sortField: 'staff_code',
  },
  {
    title: '名前',
    sortField: 'staff_name',
  },
  {
    title: '権限',
    sortField: 'staff_auth',
    render: staffAuth =>
      staffAuthOptions.find(auth => auth.value === staffAuth)?.label,
  },
  {
    title: 'ブランド',
    sortField: 'brand_name',
    dataIndex: 'brand',
    render: brand => brand?.brand_name,
  },
  {
    title: '店舗',
    sortField: 'shop_name',
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      ({
        0: '使用中',
        1: '使用不可',
        2: '削除済み',
      }[status]),
  },
];

export const nameColumns = [
  {
    title: '名称CD',
    sortField: 'select_option_code',
  },
  {
    title: 'フィールド名',
    sortField: 'field_name',
  },
  {
    title: 'ラベル',
    sortField: 'label',
  },
  {
    title: '名称',
    sortField: 'update_label',
  },
  {
    title: '表示優先順',
    sortField: 'display_order'
  },
];

export const topImagecolumns = [
  {
    title: 'CD',
    sortField: 'topimage_code',
  },
  {
    title: 'タイトル',
    sortField: 'title',
  },
  {
    title: 'サブタイトル',
    sortField: 'sub_title',
  },
  {
    title: '掲載開始日時',
    sortField: 'start_post_period',
    render: v => dateFormat(v, 'YYYY/M/D HH:mm'),
  },
  {
    title: '掲載終了日時',
    sortField: 'end_post_period',
    render: v => dateFormat(v, 'YYYY/M/D HH:mm'),
  },
  {
    title: 'スライダー',
    sortField: 'slider',
  },
  {
    title: '表示順',
    sortField: 'display_order',
  },
  {
    title: '詳細',
    sortField: 'detail',
  },
  {
    title: 'ステータス',
    sortField: 'status',
  },
];

export const contentsColumns = [
  {
    title: 'CD',
    sortField: 'contents_code',
  },
  {
    title: 'タイトル',
    sortField: 'title',
  },
  {
    title: 'サブタイトル',
    sortField: 'sub_title',
  },
  {
    title: '掲載開始日時',
    sortField: 'start_post_period',
    render: v => dateFormat(v, 'YYYY/M/D HH:mm'),
  },
  {
    title: '掲載終了日時',
    sortField: 'end_post_period',
    render: v => dateFormat(v, 'YYYY/M/D HH:mm'),
  },
  {
    title: 'コンテンツ種別',
    sortField: 'contents_type',
  },
  {
    title: 'スライダー',
    sortField: 'slider',
  },
  {
    title: '表示順',
    sortField: 'display_order',
  },
  {
    title: '詳細',
    sortField: 'detail',
  },
  {
    title: 'ステータス',
    sortField: 'status',
  },
];

export const couponColumns = [
  {
    title: 'クーポンCD',
    sortField: 'coupon_code',
  },
  {
    title: 'クーポン名',
    sortField: 'coupon_name',
  },
  {
    title: 'クーポン種別',
    sortField: 'coupon_type',
  },
  {
    title: 'クーポン利用期間',
    sortField: 'usage_period',
  },
  {
    title: 'クーポン利用回数',
    sortField: 'usage_count',
  },
  {
    title: 'ステータス',
    sortField: 'status',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const couponResultColumns = [
  {
    title: 'クーポンCD',
    sortField: 'coupon_code',
  },
  {
    title: 'クーポン名',
    sortField: 'coupon_name',
  },
  {
    title: 'クーポン種別',
    sortField: 'coupon_type',
  },
  {
    title: '台紙CD',
    sortField: 'couponcard_code',
    defaultHiddenFiled: true,
  },
  {
    title: '台紙名',
    sortField: 'couponcard_name',
    defaultHiddenFiled: true,
  },
  {
    title: '対象ブランド',
    sortField: 'brands',
    defaultHiddenFiled: true,
  },
  {
    title: '対象店舗',
    sortField: 'shops',
    defaultHiddenFiled: true,
  },
  {
    title: '配信日',
    sortField: 'delivery_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '配信数',
    sortField: 'delivery_count',
  },
  {
    title: '開封数',
    sortField: 'open_count',
  },
  {
    title: '利用数',
    sortField: 'used_count',
  },
];

export const couponUserResultColumns = [
  {
    title: '会員番号',
    sortField: 'user_code',
  },
  {
    title: 'クーポンCD',
    sortField: 'coupon_code',
  },
  {
    title: 'クーポン名',
    sortField: 'coupon_name',
  },
  {
    title: '台紙CD',
    sortField: 'couponcard_code',
    defaultHiddenFiled: true,
  },
  {
    title: '台紙名',
    sortField: 'couponcard_name',
    defaultHiddenFiled: true,
  },
  {
    title: '対象ブランド',
    sortField: 'brands',
    defaultHiddenFiled: true,
  },
  {
    title: '対象店舗',
    sortField: 'shops',
    defaultHiddenFiled: true,
  },
  {
    title: '配信日',
    sortField: 'delivery_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '開封日',
    sortField: 'open_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '利用ステータス',
    sortField: 'coupon_status',
  },
  {
    title: '利用状況',
    sortField: 'coupon_used_flg',
  },
  {
    title: '残利用回数',
    sortField: 'coupon_available_count',
  },
  {
    title: '利用日',
    sortField: 'used_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '失効日',
    sortField: 'expire_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const reserveColumns = [
  {
    title: 'CD',
    sortField: 'document_id',
  },
  {
    title: '種別',
    sortField: 'send_type',
    render: status =>
      sendMethodTypeOptions.find(op => op.value === status)?.label ?? '-',
  },
  {
    title: 'タイトル',
    sortField: 'title',
  },
  {
    title: 'メッセージ',
    sortField: 'message',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '配信日',
    sortField: 'send_plan_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: 'ステータス',
    sortField: 'send_status',
    render: status => sendStatusOptions.find(op => op.value === status)?.label,
  },
  {
    title: 'リピート',
    sortField: 'repeat_type',
    render: type => repeatTypeOptions.find(op => op.value === type)?.label,
  },
];

export const geopushColumns = [
  {
    title: 'CD',
    sortField: 'document_id',
  },
  {
    title: 'タイトル',
    sortField: 'title',
  },
  {
    title: 'メッセージ',
    sortField: 'message',
  },
  {
    title: '店舗',
    sortField: 'shop_name_list',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const geopushInfoColumns = [
  {
    title: '名称',
    sortField: 'location_name',
    width: 230,
    placeHolder: '名称を入力してください',
  },
  {
    title: '緯度',
    sortField: 'latitude',
    placeHolder: '',
  },
  {
    title: '経度',
    sortField: 'longitude',
    placeHolder: '',
  },
  {
    title: '半径',
    sortField: 'radius',
    placeHolder: '00',
  },
];

export const mapLocationInfoColumns = [
  {
    title: '名称',
    sortField: 'location_name',
    width: 230,
    placeHolder: '名称を入力してください',
  },
  {
    title: '緯度',
    sortField: 'latitude',
    placeHolder: '',
  },
  {
    title: '経度',
    sortField: 'longitude',
    placeHolder: '',
  },
  {
    title: '半径',
    width: 124,
    sortField: 'radius',
    placeHolder: '00',
  },
];

export const notificationColumns = [
  {
    title: 'CD',
    sortField: 'document_id',
  },
  {
    title: 'タイトル',
    sortField: 'title',
  },
  {
    title: 'メッセージ',
    sortField: 'message',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const stampColumns = [
  {
    title: '台紙CD',
    sortField: 'stamp_code',
  },
  {
    title: '台紙名',
    sortField: 'stamp_name',
  },
  {
    title: 'スタンプ数',
    sortField: 'stamp_count',
  },
  {
    title: '有効期限',
    sortField: 'expiry_date',
    render: (_, record) =>
      `${
        { 0: '台紙初回押印日から', 1: '最終押印日から' }[record.valid_days_flag]
      }${record.valid_days ?? ''}日`,
  },
  {
    title: '台紙表示期間',
    sortField: 'display_start_date',
    render: (_, record) =>
      `${dateFormat(record.display_start_date, 'YYYY/M/D')}\r\n~${dateFormat(
        record.display_end_date,
        'YYYY/M/D'
      )}`,
  },
  {
    title: 'ブランド',
    sortField: 'brands',
    render: brands => (
      <span className="display-linebreak">
        {brands
          ? `${brands[0]?.brand_name ?? ''}${
              brands.length > 1 ? `\n他${brands.length - 1}ブランド` : ''
            }`
          : ''}
      </span>
    ),
  },
  {
    title: '店舗',
    sortField: 'shops',
    render: shops => (
      <span className="display-linebreak">
        {shops
          ? `${shops[0]?.shop_name ?? ''}${
              shops.length > 1 ? `\n他${shops.length - 1}店舗` : ''
            }`
          : ''}
      </span>
    ),
  },
  {
    title: 'クーポンCD',
    sortField: 'coupon_code',
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      ({
        0: '開始日前',
        1: '使用中',
        2: '期間外',
        3: '削除済み',
      }[status]),
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const stampResultColumns = [
  {
    title: 'クーポンCD',
    sortField: 'coupon_cd',
  },
  {
    title: 'クーポン名',
    sortField: 'coupon_name',
  },
  {
    title: 'クーポン種別',
    sortField: 'coupon_type',
  },
  {
    title: '台紙CD',
    sortField: 'mount_cd',
  },
  {
    title: '台紙名',
    sortField: 'mount_name',
  },
  {
    title: '対象店舗',
    sortField: 'shop_name',
  },
  {
    title: '配信日',
    sortField: 'delivery_date',
  },
  {
    title: '配信数',
    sortField: 'delivery_count',
  },
  {
    title: '開封数',
    sortField: 'open_count',
  },
  {
    title: '利用数',
    sortField: 'use_count',
  },
];

export const mountUserResultColumns = [
  {
    title: '会員番号',
    sortField: 'user_code',
  },
  {
    title: '台紙CD',
    sortField: 'stamp_code',
  },
  {
    title: '台紙名',
    sortField: 'stamp_name',
  },
  {
    title: '対象ブランド',
    sortField: 'brand_list',
    render: brands => (
      <span className="display-linebreak">
        {brands
          ? `${brands[0]?.brand_name ?? ''}${
              brands.length > 1 ? `\n他${brands.length - 1}ブランド` : ''
            }`
          : ''}
      </span>
    ),
  },
  {
    title: '対象店舗',
    sortField: 'shop_list',
    render: shops => (
      <span className="display-linebreak">
        {shops
          ? `${shops[0]?.shop_name ?? ''}${
              shops.length > 1 ? `\n他${shops.length - 1}店舗` : ''
            }`
          : ''}
      </span>
    ),
  },
  {
    title: '台紙n枚目',
    sortField: 'stamp_n_page',
  },
  {
    title: '台紙内スタンプ数',
    sortField: 'stamp_count',
  },
  {
    title: '最新台紙失効日',
    sortField: 'expire_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '最新台紙初回押印日',
    sortField: 'first_stamp_date',
    render: v => dateFormat(v, 'YYYY/M/D', '-'),
  },
  {
    title: '最新台紙最終押印日',
    sortField: 'last_stamp_date',
    render: v => dateFormat(v, 'YYYY/M/D', '-'),
  },
];

export const qrColumns = [
  {
    title: 'QRコード',
    sortField: 'qr_code',
  },
  {
    title: 'QR名',
    sortField: 'qr_name',
  },
  {
    title: '',
    sortField: 'qr_button',
    width: 152,
  },
  {
    title: 'スタンプ押印数',
    sortField: 'stamp_count',
  },
  {
    title: 'ブランド',
    sortField: 'brand_name',
  },
  {
    title: '店舗',
    sortField: 'shop_name',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    width: 120,
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const qrResultColumns = [
  {
    title: 'クーポンCD',
    sortField: 'coupon_cd',
  },
  {
    title: 'クーポン名',
    sortField: 'coupon_name',
  },
  {
    title: 'クーポン種別',
    sortField: 'coupon_type',
  },
  {
    title: '台紙CD',
    sortField: 'mount_cd',
  },
  {
    title: '台紙名',
    sortField: 'mount_name',
  },
  {
    title: '対象店舗',
    sortField: 'shop_name',
  },
  {
    title: '配信日',
    sortField: 'delivery_date',
  },
  {
    title: '配信数',
    sortField: 'delivery_count',
  },
  {
    title: '開封数',
    sortField: 'open_count',
  },
  {
    title: '利用数',
    sortField: 'use_count',
  },
];

export const qrUserResultColumns = [
  {
    title: '会員番号',
    sortField: 'user_cd',
  },
  {
    title: 'クーポンCD',
    sortField: 'coupon_cd',
  },
  {
    title: 'クーポン名',
    sortField: 'coupon_name',
  },
  {
    title: '台紙CD',
    sortField: 'mount_cd',
  },
  {
    title: '台紙名',
    sortField: 'mount_name',
  },
  {
    title: '対象店舗',
    sortField: 'shop_name',
  },
  {
    title: '配信日',
    sortField: 'delivery_date',
  },
  {
    title: '開封日',
    sortField: 'open_date',
  },
  {
    title: '利用ステータス',
    sortField: 'use_status',
  },
  {
    title: '利用状況',
    sortField: 'use_situation',
  },
  {
    title: '残利用回数',
    sortField: 'use_count',
  },
  {
    title: '利用日',
    sortField: 'use_date',
  },
  {
    title: '失効日',
    sortField: 'invalid_date',
  },
];

export const stampedDetailColumns = [
  {
    title: '会員番号',
    sortField: 'user_code',
  },
  {
    title: '台紙CD',
    sortField: 'stamp_code',
  },
  {
    title: '台紙名',
    sortField: 'stamp_name',
  },
  {
    title: '押印日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '押印ブランド',
    sortField: 'brand_code',
    render: (_, record) => record.brand_name,
  },
  {
    title: '押印店舗',
    sortField: 'shop_code',
    render: (_, record) => record.shop_name,
  },
  {
    title: 'QRコード',
    sortField: 'qr_code',
  },
  {
    title: '押印数',
    sortField: 'stamp_count',
  },
  {
    title: '台紙n枚目',
    sortField: 'stamp_n_page',
  },
];

export const stampRallyColumns = [
  {
    title: '台紙CD',
    sortField: 'stamp_rally_code',
  },
  {
    title: '台紙名',
    sortField: 'stamp_rally_name',
  },
  {
    title: 'スタンプ数',
    sortField: 'stamp_rally_count',
  },
  {
    title: '有効期限',
    sortField: 'expire_date',
    render: (_, record) =>
      `${
        { 0: '台紙初回押印日から', 1: '最終押印日から' }[record.valid_days_flag]
      }${record.valid_days ?? ''}日`,
  },
  {
    title: '台紙表示期間',
    sortField: 'display_start_date',
    render: (_, record) =>
      `${dateFormat(record.display_start_date, 'YYYY/M/D')}\n〜${dateFormat(
        record.display_end_date,
        'YYYY/M/D'
      )}`,
  },
  {
    title: '交換種別',
    sortField: 'exchange_type',
    render: status =>
      ({
        1: 'ポイント付与',
        2: 'クーポン',
      }[status]),
  },
  {
    title: 'クーポンCD',
    sortField: 'coupon_code',
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      ({
        0: '開始日前',
        1: '使用中',
        2: '期間外',
        3: '削除済み',
      }[status]),
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const stampRallyResultColumns = [
  {
    title: '会員番号',
    sortField: 'user_code',
  },
  {
    title: '台紙CD',
    sortField: 'stamp_rally_code',
  },
  {
    title: '台紙名',
    sortField: 'stamp_rally_name',
  },
  {
    title: '押印日',
    sortField: 'stamp_rally_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: 'トリガー',
    sortField: 'trigger',
    render: status =>
      stampRallyTriggerTypeOptions.find(v => v.value === status).label,
  },
  {
    title: '押印数',
    sortField: 'stamp_rally_count',
  },
  {
    title: '台紙n枚目',
    sortField: 'stamp_rally_n_page',
  },
];

export const stampRallyUserResultColumns = [
  {
    title: '会員番号',
    sortField: 'user_code',
  },
  {
    title: '台紙CD',
    sortField: 'stamp_rally_code',
  },
  {
    title: '台紙名',
    sortField: 'stamp_rally_name',
  },
  {
    title: '台紙n枚目',
    sortField: 'stamp_rally_n_page',
  },
  {
    title: '台紙内スタンプ数',
    sortField: 'stamp_rally_count',
  },
  {
    title: '最新台紙失効日',
    sortField: 'expire_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '最新台紙初回押印日',
    sortField: 'first_stamp_rally_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: '最新台紙最終押印日',
    sortField: 'last_stamp_rally_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const pointExchangeColumns = [
  {
    title: '会員番号',
    sortField: 'user_code',
  },
  {
    title: '台紙CD',
    sortField: 'stamp_rally_code',
  },
  {
    title: '台紙名',
    sortField: 'stamp_rally_name',
  },
  {
    title: '交換日',
    sortField: 'exchange_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: 'ポイント数',
    sortField: 'point',
  },
  {
    title: '台紙n枚目',
    sortField: 'stamp_rally_n_page',
  },
];

export const enqueteColumns = [
  {
    title: 'アンケートCD',
    sortField: 'enquete_code',
  },
  {
    title: 'アンケート名',
    sortField: 'enquete_name',
  },
  {
    title: 'アンケートカテゴリ',
    sortField: 'category_name',
  },
  {
    title: 'アンケート期間',
    sortField: 'public_start_date',
    render: (_, record) =>
      `${dateFormat(
        record.public_start_date,
        'YYYY/M/D HH:mm'
      )}\r\n~${dateFormat(record.public_end_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: '回答数',
    sortField: 'answer_count',
    render: count => count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  },
];

export const categoryColumns = [
  {
    title: 'カテゴリCD',
    sortField: 'category_code',
  },
  {
    title: 'カテゴリ種別',
    sortField: 'category_type',
  },
  {
    title: 'カテゴリ名',
    sortField: 'category_name',
  },
  {
    title: '表示優先順',
    sortField: 'display_order',
  },
  {
    title: 'ステータス',
    sortField: 'deleted_flg',
    render: status =>
      ({
        0: '使用中',
        1: '削除済み',
      }[status]),
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const lotteryColumns = [
  {
    title: '抽選CD',
    sortField: 'lottery_code',
  },
  {
    title: '抽選名',
    sortField: 'lottery_name',
  },
  {
    title: 'キャンペーン表示期間',
    sortField: 'public_start_date',
    render: (_, record) =>
      `${dateFormat(
        record.public_start_date,
        'YYYY/M/D HH:mm'
      )}\r\n~${dateFormat(record.public_end_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: '応募受付期間',
    sortField: 'entry_public_start_date',
    render: (_, record) =>
      `${dateFormat(
        record.entry_public_start_date,
        'YYYY/M/D HH:mm'
      )}\r\n~${dateFormat(record.entry_public_end_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: '抽選結果公開日',
    sortField: 'result_public_date',
    render: (_, record) =>
      `${dateFormat(record.result_public_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: '応募総数',
    sortField: 'total_entry_count',
    render: count =>
      count?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0,
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      status === 0 ? '期間外' : status === 1 ? '抽選中' : '削除済み',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: (_, record) => `${dateFormat(record.create_at, 'YYYY/M/D')}`,
  },
];

export const lotteryApplicantColumns = [
  {
    title: '抽選CD',
    sortField: 'lottery_code',
  },
  {
    title: '抽選名',
    sortField: 'lottery_name',
  },
  {
    title: '応募日時',
    sortField: 'entry_date',
    render: (_, record) => `${dateFormat(record.entry_date, 'YYYY/M/D')}`,
  },
  {
    title: '会員番号',
    sortField: 'user_code',
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      status === 0 ? '当選者取込前' : status === 1 ? '当選' : 'ハズレ',
  },
  {
    title: '当選景品',
    sortField: 'coupon_code',
  },
  {
    title: '景品受取日時',
    sortField: 'coupon_used_date',
    render: (_, record) =>
      `${dateFormat(record.coupon_used_date, 'YYYY/M/D') ?? '-'}`,
  },
];

export const gachaColumns = [
  {
    title: 'ガチャCD',
    sortField: 'gacha_code',
  },
  {
    title: 'ガチャ名',
    sortField: 'gacha_name',
  },
  {
    title: 'トリガー',
    sortField: 'trigger',
    render: status =>
      gachaTriggerTypeOptions.find(v => v.value === status).label,
  },
  {
    title: 'キャンペーン表示期間',
    sortField: 'campaign_start_date',
    render: (_, record) =>
      `${dateFormat(
        record.campaign_start_date,
        'YYYY/M/D HH:mm'
      )}\r\n~${dateFormat(record.campaign_end_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: 'ガチャ実施期間',
    sortField: 'gacha_start_date',
    render: (_, record) =>
      `${dateFormat(
        record.gacha_start_date,
        'YYYY/M/D HH:mm'
      )}\r\n~${dateFormat(record.gacha_end_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      gachaStatusTypeOptions.find(v => v.value === status).label,
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
];

export const gachaApplicantColumns = [
  { title: 'ガチャCD', sortField: 'gacha_code' },
  { title: 'ガチャ名', sortField: 'gacha_name' },
  {
    title: 'トリガー',
    sortField: 'trigger',
    render: status =>
      gachaTriggerTypeOptions.find(v => v.value === status)?.label,
  },
  { title: '店舗', sortField: 'location_name' },
  { title: '会員番号', sortField: 'user_code' },
  {
    title: '参加日',
    sortField: 'applicant_date',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: 'ステータス',
    sortField: 'winner_rank',
    render: winner_rank =>
      gachaResultStatusTypeOptions.find(v => v.value === winner_rank)?.label,
  },
  {
    title: '当選景品種別',
    sortField: 'gift_type',
    render: gift_type =>
      gachaGiftTypeOptions.find(v => v.value === gift_type)?.label ?? '-',
  },
  {
    title: '当選景品',
    sortField: 'gift',
    render: (v, p) =>
      v === null ? '-' : p.gift_type === '1'
        ? v : p.gift_type === '2'
          ? `${thousandsSeparator(v)}個`
          : `${thousandsSeparator(v)}pt`,
  },
];

export const lineRichmenuColumns = [
  {
    title: 'メニューCD',
    sortField: 'menu_code',
  },
  {
    title: 'メニュー名',
    sortField: 'menu_name',
  },
  {
    title: '公式アカウント名',
    sortField: 'account_name',
  },
  {
    title: 'メニュー表示期間',
    sortField: 'start_date',
    render: (_, record) =>
      `${dateFormat(record.start_date, 'YYYY/M/D HH:mm')}\r\n~${dateFormat(
        record.end_date,
        'YYYY/M/D HH:mm'
      )}`,
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: v => dateFormat(v, 'YYYY/M/D'),
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      [
        { label: '表示中', value: 1 },
        { label: '表示期間外', value: 2 },
        { label: '削除済み', value: 9 },
      ].find(v => v.value === status)?.label,
  },
];

export const lineAccountColumns = [
  {
    title: '公式アカウントCD',
    sortField: 'account_code',
  },
  {
    title: '公式アカウント名',
    sortField: 'account_name',
  },
  {
    title: 'プロバイダID',
    sortField: 'provider_id',
  },
  {
    title: 'チャネルID',
    sortField: 'channel_id',
  },
  {
    title: 'チャネルアクセストークン',
    sortField: 'channel_access_token',
  },
  {
    title: 'ステータス',
    sortField: 'delete_flag',
    render: status =>
      ({
        0: '使用中',
        1: '削除済み',
      }[status]),
  },
];

export const templateColumns = [
  {
    title: '定型文CD',
    sortField: 'template_code',
  },
  {
    title: '定型文種別',
    sortField: 'template_type',
    render: template_type => {
      const labels = template_type.map(type => {
        const matchingOption = templateOptions.find(option => option.value === type);
        return matchingOption ? matchingOption.label : '';
      });
      return labels.join(' ');
    }
  },
  {
    title: '定型文タイトル',
    sortField: 'template_title',
  },
  {
    title: 'ステータス',
    sortField: 'deleted_flag',
    render: deleted_flag =>
      deleted_flag === "0" ? '使用中' : '削除済み',
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: (_, record) => `${dateFormat(record.create_at, 'YYYY/M/D')}`,
  },
];

export const adventCalendarColumns = [
  {
    title: 'アドベントカレンダーCD',
    sortField: 'advent_calendar_code',
  },
  {
    title: 'アドベントカレンダー名',
    sortField: 'advent_calendar_name',
  },
  {
    title: 'キャンペーン表示期間',
    sortField: 'display_start_date',
    render: (_, record) =>
      `${dateFormat(
        record.display_start_date,
        'YYYY/M/D HH:mm'
      )}\r\n~${dateFormat(record.display_end_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: 'アドベントカレンダー実施期間',
    sortField: 'advent_calendar_start_date',
    render: (_, record) =>
      `${dateFormat(
        record.advent_calendar_start_date,
        'YYYY/M/D HH:mm'
      )}\r\n~${dateFormat(record.advent_calendar_end_date, 'YYYY/M/D HH:mm')}`,
  },
  {
    title: 'ステータス',
    sortField: 'status',
    render: status =>
      ({
        0: '期間外',
        1: '実施中',
        2: '削除済み',
      }[status]),
  },
  {
    title: '登録日',
    sortField: 'create_at',
    render: (_, record) => `${dateFormat(record.create_at, 'YYYY/M/D')}`,
  },
];