import './gachaShopListStyle.scss';
import Icons from '../../constants/Icons';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import TextBox from '../util/textBox';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import MapElement from '../mapElement';
import { useSelector } from 'react-redux';
import InputGift from './inputGift';
import { Tooltip } from 'antd';

const ShopGiftListElement = ({ formik, trigger, isUpdate, isCopy }) => {
  const { accountBrandList, accountShopList } = useSelector(
    state => state.account
  );
  const { initialLocationGiftList, initialLocation } = useSelector(
    state => state.gacha
  );

  const [isCardOpen, setIsCardOpen] = useState([true]);
  const [availableShops, setAvailableShops] = useState([]);

  const { location_gift_list, lottery_method } = formik?.values ?? [];

  // カードの開閉処理
  const cardOpenToggle = listIndex => {
    let newAry = [...isCardOpen];
    newAry[listIndex] = !isCardOpen[listIndex];
    setIsCardOpen(newAry);
  };

  // 選択した店舗を文字列で返す処理
  const selectedShopListStr = idx => {
    const { locations } = location_gift_list[idx];
    if (locations.length === 0) {
      return '-';
    }
    return locations.map(shop => shop.location_name).join(',');
  };

  // 選択済みのショップリストを除外する処理
  const exclusionSelectedShops = () => {
    return accountShopList.filter(
      shop =>
        availableShops.length === 0 ||
        availableShops.some(a => a.shop_code === shop.shop_code)
    );
  };

  // 当選形式が本数で更新時はtrueを返す
  const isAddCardAndDelete = () => {
    return !isCopy && isUpdate && lottery_method === '0';
  };

  // トリガーが選択されていないか確認
  const isTrigger = () => trigger === null;

  // 初回起動
  const isLogin = () => ['0', '3'].includes(trigger);

  // 来店チェックイン
  const isCheckIn = () => ['2', '5'].includes(trigger);

  useEffect(() => {
    // 複製画面の時はカードを全て開いた状態
    if (isUpdate) {
      const newIsCardOpen = formik.values.location_gift_list.map(() => true);
      setIsCardOpen(newIsCardOpen);
    }
  }, []);

  return location_gift_list?.map((gachaShop, idx) => (
    <div
      className={`gacha-shop_gift_list-area ${
        isTrigger() && 'gacha-shop_gift_list-area-disabled'
      }`}
    >
      <div
        className={`gacha-shop_gift_list-card ${
          !isCheckIn() ? 'list-card-no-padding' : ''
        }`}
        key={`gacha-shop_gift-card-${idx}`}
        style={isCardOpen[idx] ? { paddingBottom: 32 } : {}}
      >
        {isCheckIn() ? (
          <div
            className={'gacha-shop_gift_list-header-area'}
            style={
              isCardOpen[idx]
                ? { borderBottom: '1px solid #f5f5f5', paddingBottom: 16 }
                : {}
            }
          >
            <div
              className={'gacha-shop_gift_list-header'}
              style={{ width: idx === 0 ? '100%' : '' }}
            >
              <div className={'gacha-shop_gift_list-open-btn-area'}>
                <Icon
                  component={
                    isTrigger()
                      ? Icons.IconChevronDownGy
                      : isCardOpen[idx]
                      ? Icons.IconChevronUp
                      : Icons.IconChevronDown
                  }
                  onClick={() => cardOpenToggle(idx)}
                />
              </div>
              <div className={'gacha-shop_gift_list-title-area'}>
                {isCardOpen[idx] ? (
                  <TextBox
                    formik={formik}
                    fieldName={`location_gift_list[${idx}].group_name`}
                    placeholder="店舗別設定名を入力してください"
                    width={'100%'}
                  />
                ) : (
                  <div className={`${isTrigger() && 'group_name-disabled'}`}>
                    <div className={`gacha-shop_gift_list-title`}>
                      {location_gift_list[idx].group_name}
                    </div>
                    <div className={'gacha-shop_gift_list-target-shop'}>
                      {`対象店舗　${selectedShopListStr(idx)}`}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!isAddCardAndDelete() && idx !== 0 ? (
              <div className={'gacha-shop_gift_list-delete-btn-area'}>
                <Icons.IconXCircleWh
                  onClick={() => {
                    // カードの開閉情報を削除
                    let newIsCardOpenList = [...isCardOpen];
                    newIsCardOpenList.splice(idx, 1);
                    setIsCardOpen(newIsCardOpenList);

                    // 現在選択しているショップをAvailableShopsに戻す
                    setAvailableShops([
                      ...location_gift_list?.[idx].selected_shop_list,
                    ]);

                    // 対象のlocation_gift_listを削除
                    let newShopGiftList = [...location_gift_list];
                    newShopGiftList.splice(idx, 1);
                    handlerFormikFieldChange(
                      formik,
                      'location_gift_list',
                      newShopGiftList
                    );
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        {isCardOpen[idx] ? (
          <div
            className={`gacha-shop_gift_list-contents-area ${
              !isCheckIn() ? 'contents-area-no-padding' : ''
            }`}
          >
            {isCheckIn() ? (
              <MapElement
                accountBrandList={accountBrandList}
                accountShopList={exclusionSelectedShops()}
                formik={formik}
                selectedShopList={location_gift_list?.[idx].selected_shop_list}
                selectShopListFieldName={`location_gift_list[${idx}].selected_shop_list`}
                shopLocations={location_gift_list?.[idx].locations}
                shopLocationFieldName={`location_gift_list[${idx}].locations`}
                availableShops={availableShops}
                setAvailableShops={setAvailableShops}
                initialLocation={initialLocation}
              />
            ) : (
              <></>
            )}
            <InputGift
              formik={formik}
              isUpdate={!isCopy && isUpdate}
              disabled={isTrigger()}
              lottery_disabled={lottery_method === '0' && !isCopy && isUpdate}
              inputGiftFieldClassName={`gacha-shop_gift_list-gift-field ${
                isTrigger() ? 'gift-field-disabled' : ''
              }`}
              gift_list={location_gift_list?.[idx].gift_list}
              gift_list_field_name={`location_gift_list[${idx}].gift_list`}
              isLogin={isLogin()}
              loserProbability={location_gift_list?.[idx].loser_probability}
              loserProbabilityFieldName={`location_gift_list[${idx}].loser_probability`}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {!isAddCardAndDelete() &&
      isCheckIn() &&
      !isTrigger() &&
      location_gift_list.length - 1 === idx ? (
        <div className="add-btn-area">
          <Tooltip
            title="入力枠追加"
            color="#C4A6A9"
            overlayClassName="add-tooltip"
          >
            <Icons.IconPlus
              className="plus-icon"
              onClick={() => {
                // カードを開いたまま追加
                let newAryCardOpened = [...isCardOpen];
                newAryCardOpened.push(true);
                setIsCardOpen(newAryCardOpened);

                // リスト追加
                let newAry = [...location_gift_list];
                newAry.push({
                  ...initialLocationGiftList,
                  gift_list: initialLocationGiftList.gift_list.map(gift => {
                    return {
                      ...gift,
                      update_gift: true,
                    };
                  }),
                });
                handlerFormikFieldChange(formik, 'location_gift_list', newAry);
              }}
            />
          </Tooltip>
        </div>
      ) : (
        <></>
      )}
    </div>
  ));
};

export default ShopGiftListElement;
