import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalTitle from '../modalTitle';
import {
  fetchGachaCreate,
  fetchGachaUpdate,
  fetchGachaTemplateList,
  gachaActions,
} from '../../slices/gachaSlice';
import { useFormik } from 'formik';
import Yup from '../../utils/yupUtil';
import {
  convertRichText,
  convertText,
  deleteLinkRichText,
  handlerFormikFieldChange,
} from '../../utils/fnUtil';
import TcRadio from '../tcCheckbox/tcRadio';
import {
  checkinRuleOptions,
  flagOptions,
  winningOptions,
} from '../../constants/options';
import TcButton from '../tcButton';
import Icons from '../../constants/Icons';
import ImagesUploader from '../imagesUploader';
import ContentsTooltip from '../contentsTooltip';
import { globalActions } from '../../slices/globalSlice';
import moment from 'moment';
import { getOptionLabel } from '../../utils/fnUtil';
import SelectBox from '../util/selectBox';
import CustomNumberPicker from '../util/customNumberPicker';
import TextBox from '../util/textBox';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import './style.scss';
import VideoImageElement from '../videoImageElement';
import ShopGiftListElement from './shopGiftListElement';

const GachaModal = () => {
  const dispatch = useDispatch();

  const format = (date, format) => (date ? moment(date)?.format(format) : null);

  const {
    accountInfo: {
      new_contents_flag,
      add_article_data: { video_upload_flag },
    },
  } = useSelector(state => state.account);

  const { selectOptionList } = useSelector(state => state.name);

  const {
    editModalVisible,
    currentGacha,
    templateList,
    mode,
    initialValues,
  } = useSelector(state => state.gacha);

  const isUpdate = !!currentGacha;
  const isCopy = mode === 'copy';

  // Yupバリデーションオブジェクトの定義開始(triggerの値に基づいて条件付きで必須または除去される)
  const yupObject = {
    trigger: Yup.string().nullable().required(),
    consecutive_startup_days: Yup.string()
      .nullable()
      .when('trigger', (trigger, schema) =>
        ['1', '4'].includes(trigger) ? schema.required() : schema.strip()
      ),
    banner_image_url: Yup.string().nullable().required(),
    gacha_name: Yup.string().nullable().required().max(50),
    gacha_content: Yup.string()
      .nullable()
      .test(
        'gacha_content',
        'ガチャ説明を入力してください',
        (value, testContext) => {
          const richContent = testContext.parent.gacha_rich_content;
          return (
            (new_contents_flag !== 0 &&
              (richContent?.length > 1 ||
                richContent.some(r => (r.children ?? []).some(c => c.text)))) ||
            value
          );
        }
      ),
    campaign_start_date: Yup.string()
      .nullable()
      .test(
        'campaign_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.campaign_start_hour &&
          testContext.parent.campaign_start_minute
      ),
    campaign_end_date: Yup.string()
      .nullable()
      .test(
        'campaign_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.campaign_end_hour &&
          testContext.parent.campaign_end_minute
      ),
    gacha_start_date: Yup.string()
      .nullable()
      .test(
        'gacha_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.gacha_start_hour &&
          testContext.parent.gacha_start_minute
      ),
    gacha_end_date: Yup.string()
      .nullable()
      .test(
        'gacha_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.gacha_end_hour &&
          testContext.parent.gacha_end_minute
      ),
    lottery_method: Yup.string(),
    location_gift_list: Yup.array().of(
      Yup.object({
        locations: Yup.array().when((_, schema) => {
          return ['2', '5'].includes(formik.values.trigger)
            ? schema.min(1, '少なくとも1つのロケーションが必要です').of(
                Yup.object({
                  location_name: Yup.string().nullable().required(),
                  latitude: Yup.string()
                    .nullable()
                    .required()
                    .matches(
                      /^-?(90(\.0+)?|[1-8]?[0-9](\.\d+)?)$/,
                      '-90~90の範囲で小数点を含む数値を入力してください'
                    )
                    .test('range', '-90~90の範囲で入力してください', value => {
                      if (value) {
                        const num = parseFloat(value);
                        return num >= -90 && num <= 90;
                      }
                      return true;
                    }),
                  longitude: Yup.string()
                    .nullable()
                    .required()
                    .matches(
                      /^-?(180(\.0+)?|1?[0-7]?[0-9](\.\d+)?)$/,
                      '-180~180の範囲で小数点を含む数値を入力してください'
                    )
                    .test(
                      'range',
                      '-180~180の範囲で入力してください',
                      value => {
                        if (value) {
                          const num = parseFloat(value);
                          return num >= -180 && num <= 180;
                        }
                        return true;
                      }
                    ),
                  radius: Yup.string()
                    .nullable()
                    .required()
                    .matches(
                      /^[3-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$|10000/,
                      '300~10000の数値を入力してください'
                    ),
                })
              )
            : schema.strip();
        }),
        gift_list: Yup.array().of(
          Yup.object({
            stamp_count: Yup.string()
              .nullable()
              .when('is_checked', (is_checked, schema) =>
                schema
                  .test('stamp_count', '必須', function (value) {
                    if (is_checked) {
                      return ['0', '1', '2'].includes(trigger) ? value : true;
                    } else {
                      return true;
                    }
                  })
                  .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります')
              ),
            point: Yup.string()
              .nullable()
              .when('is_checked', (is_checked, schema) =>
                schema
                  .test('point', '必須', function (value) {
                    if (is_checked) {
                      return ['3', '4', '5'].includes(trigger) ? value : true;
                    } else {
                      return true;
                    }
                  })
                  .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります')
              ),
            max_winner_count: Yup.string()
              .nullable()
              .when(['is_checked', 'rank'], (is_checked, rank, schema) =>
                !is_checked
                  ? schema.strip()
                  : schema
                      .test('max_winner_count', '必須', function (value) {
                        const lottery_method =
                          this?.options?.context?.lottery_method;
                        return lottery_method === '0' && rank !== '9'
                          ? !!value
                          : schema.strip();
                      })
                      .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります')
              ),
            winner_probability: Yup.string()
              .nullable()
              .when(['is_checked', 'rank'], (is_checked, rank, schema) =>
                !is_checked
                  ? schema.strip()
                  : schema
                      .test('winner_probability', '必須', function (value) {
                        const lottery_method =
                          this?.options?.context?.lottery_method;
                        return lottery_method !== '1' && rank !== '9'
                          ? schema.strip()
                          : !!value;
                      })
                      .test(
                        'winner_probability',
                        '確率の合計が100%になるよう設定してください',
                        function (_, testContext) {
                          const { lottery_method, focused_rank, gift_list } =
                            testContext.from[1].value;
                          if (
                            lottery_method !== '1' ||
                            focused_rank !== testContext.parent.rank
                          ) {
                            return true;
                          }
                          const totalProbability = gift_list
                            .filter(v => v?.is_checked)
                            .reduce(
                              (acc, gift) =>
                                acc + (Number(gift?.winner_probability) ?? 0),
                              0
                            );
                          return totalProbability <= 100;
                        }
                      )
              ),
          })
        ),
      })
    ),
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      gacha_rich_content: toSlateState(currentGacha?.gacha_rich_content),
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: async values => {
      const {
        campaign_start_date,
        campaign_start_hour,
        campaign_start_minute,
        campaign_end_date,
        campaign_end_hour,
        campaign_end_minute,
        gacha_start_date,
        gacha_start_hour,
        gacha_start_minute,
        gacha_end_date,
        gacha_end_hour,
        gacha_end_minute,
        gacha_code,
        lottery_method,
        focused_rank,
        consecutive_startup_days,
        gacha_content,
        gacha_rich_content,
        check_in_rule,
        location_gift_list,
        ...rest
      } = values;
      const campaignStartPeriod = moment(campaign_start_date)
        .set('h', Number(campaign_start_hour))
        .set('m', Number(campaign_start_minute));
      const campaignEndPeriod = moment(campaign_end_date)
        .set('h', Number(campaign_end_hour))
        .set('m', Number(campaign_end_minute));
      const gachaStartPeriod = moment(gacha_start_date)
        .set('h', Number(gacha_start_hour))
        .set('m', Number(gacha_start_minute));
      const gachaEndPeriod = moment(gacha_end_date)
        .set('h', Number(gacha_end_hour))
        .set('m', Number(gacha_end_minute));

      // キャンペーン期間前後チェック
      if (!campaignStartPeriod.isBefore(campaignEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'キャンペーン表示開始日はキャンペーン表示終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // ガチャ実施期間前後チェック
      if (!gachaStartPeriod.isBefore(gachaEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'ガチャ実施開始日はガチャ実施終了日より前の日付を入力してください',
          })
        );
        return;
      }

      // キャンペーン表示開始日/ガチャ実施開始日前後チェック
      if (!campaignStartPeriod.isSameOrBefore(gachaStartPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'キャンペーン表示開始日はガチャ実施開始日と同じか前の日付を入力してください',
          })
        );
        return;
      }

      // ガチャ実施終了日/キャンペーン表示終了日前後チェック
      if (!gachaEndPeriod.isSameOrBefore(campaignEndPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'ガチャ実施終了日はキャンペーン表示終了日と同じか前の日付を入力してください',
          })
        );
        return;
      }

      const locationGiftList = () => {
        return location_gift_list.map(location => {
          const winnerProp = !!Number(lottery_method)
            ? 'winner_probability'
            : 'max_winner_count';

          return {
            ...location,
            group_id: !isCopy && isUpdate ? location?.group_id : null,
            gift_list: location.gift_list
              .filter(v => v?.is_checked)
              .map(v => {
                const giftProps = ['0', '1', '2'].includes(trigger)
                  ? 'stamp_count'
                  : 'point';

                return {
                  rank: v?.rank,
                  [giftProps]: v[giftProps],
                  [winnerProp]: v[winnerProp],
                };
              }),
          };
        });
      };

      const richText = deleteLinkRichText(gacha_rich_content);

      const paramsBase = {
        ...rest,
        lottery_method: lottery_method,
        campaign_start_date: campaignStartPeriod.format('YYYY-MM-DD HH:mm:ss'),
        campaign_end_date: campaignEndPeriod.format('YYYY-MM-DD HH:mm:ss'),
        gacha_start_date: gachaStartPeriod.format('YYYY-MM-DD HH:mm:ss'),
        gacha_end_date: gachaEndPeriod.format('YYYY-MM-DD HH:mm:ss'),
        gacha_rich_content: convertRichText(new_contents_flag, richText),
        gacha_content:
          new_contents_flag === 1
            ? convertText(new_contents_flag, richText, gacha_content)
            : gacha_content,
        check_in_rule: !['2', '5'].includes(trigger) ? null : check_in_rule,
        location_gift_list: locationGiftList(),
      };

      const params = !!Number(rest.trigger)
        ? {
            ...paramsBase,
            consecutive_startup_days: consecutive_startup_days,
          }
        : paramsBase;

      return isCopy
        ? dispatch(fetchGachaCreate(params))
        : isUpdate
        ? dispatch(fetchGachaUpdate({ gacha_code: gacha_code, ...params }))
        : dispatch(fetchGachaCreate(params));
    },
  });

  const {
    gacha_code,
    banner_image_url,
    trigger_temp,
    trigger,
    campaign_notice_flag,
    gacha_notice_flag,
    lottery_method,
  } = formik.values;

  const otherInputDisabled = trigger === null;

  const onBack = () => dispatch(gachaActions.closeEditModal());

  const breakDownDate = value => ({
    date: format(value, 'YYYY/MM/DD'),
    hour: format(value, 'HH'),
    minute: format(value, 'mm'),
  });

  const setValues = () => {
    const {
      date: campaign_start_date,
      hour: campaign_start_hour,
      minute: campaign_start_minute,
    } = breakDownDate(currentGacha?.campaign_start_date);

    const {
      date: campaign_end_date,
      hour: campaign_end_hour,
      minute: campaign_end_minute,
    } = breakDownDate(currentGacha?.campaign_end_date);

    const {
      date: gacha_start_date,
      hour: gacha_start_hour,
      minute: gacha_start_minute,
    } = breakDownDate(currentGacha?.gacha_start_date);

    const {
      date: gacha_end_date,
      hour: gacha_end_hour,
      minute: gacha_end_minute,
    } = breakDownDate(currentGacha?.gacha_end_date);

    const lottery_method = currentGacha.lottery_method === 0 ? '0' : '1';

    const values = {
      ...currentGacha,
      lottery_method: lottery_method,
      campaign_start_date: campaign_start_date,
      campaign_start_hour: campaign_start_hour,
      campaign_start_minute: campaign_start_minute,
      campaign_end_date: campaign_end_date,
      campaign_end_hour: campaign_end_hour,
      campaign_end_minute: campaign_end_minute,
      gacha_start_date: gacha_start_date,
      gacha_start_hour: gacha_start_hour,
      gacha_start_minute: gacha_start_minute,
      gacha_end_date: gacha_end_date,
      gacha_end_hour: gacha_end_hour,
      gacha_end_minute: gacha_end_minute,
      gacha_rich_content: toSlateState(currentGacha?.gacha_rich_content),
      template: templateList?.filter(
        e => e?.template_text === currentGacha?.fixed_phrase
      )?.[0]?.template_code,
    };

    formik.setValues(values);
  };

  const triggerElement = () => {
    return isUpdate ? (
      <div style={{ marginRight: 20 }}>
        {`${
          selectOptionList
            ?.filter(s => s.field_name === 'トリガー')?.[0]
            ?.select_option?.find(v => v.value === trigger)?.label
        }`}
      </div>
    ) : (
      <div className="trigger-box">
        <SelectBox
          formik={formik}
          fieldName={'trigger_temp'}
          options={
            selectOptionList?.filter(s => s.field_name === 'トリガー')?.[0]
              ?.select_option ?? []
          }
          uneditable={isUpdate}
          placeholder="トリガーを選択してください"
          disabled={trigger !== null}
        />
        <TcButton
          text="反映"
          style={{
            margin: 0,
            marginLeft: 5,
            marginRight: 20,
            width: 50,
          }}
          disabled={trigger !== null}
          onClick={() => {
            // formik.resetForm();

            formik.setValues({
              ...initialValues,
              trigger_temp,
              trigger: trigger_temp,
            });
          }}
        />
      </div>
    );
  };

  useEffect(
    () =>
      editModalVisible
        ? currentGacha
          ? setValues()
          : formik.validateForm()
        : formik.resetForm(),
    [editModalVisible, templateList]
  );

  useEffect(() => {
    dispatch(fetchGachaTemplateList());
  }, [editModalVisible]);

  useEffect(() => {
    if (!isUpdate) {
      const { location_gift_list } = initialValues;
      const { gift_list } = location_gift_list[0];

      let initialGiftList;

      if (['0', '3'].includes(trigger)) {
        initialGiftList = gift_list.map(gift => {
          if (gift.rank !== '9') {
            return {
              ...gift,
              is_checked: true,
            };
          } else {
            return {
              ...gift,
              is_checked: false,
            };
          }
        });
      } else {
        initialGiftList = [...gift_list];
      }

      handlerFormikFieldChange(
        formik,
        'location_gift_list.[0].gift_list',
        initialGiftList
      );
    }
  }, [trigger]);

  return (
    <Modal
      className={'gacha-modal tc-modal shop-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={
            isCopy ? 'ガチャ登録(複製)' : isUpdate ? 'ガチャ編集' : 'ガチャ登録'
          }
          tip={'*は必須項目です'}
          rightElement={triggerElement()}
        />

        <div className="input-area">
          {!isCopy && isUpdate && (
            <div className="show-area">
              <span className="pre-label">ガチャCD</span>
              <span className="font-sm">{gacha_code}</span>
            </div>
          )}

          <div className="gacha-image-display-area">
            <span className="image-area-error">
              {formik.touched?.banner_image_url &&
                formik.errors?.banner_image_url}
            </span>

            <VideoImageElement
              fieldName={'バナー画像'}
              isRequired
              btnText={'画像を追加'}
              imageUrl={banner_image_url}
              onChange={url =>
                handlerFormikFieldChange(formik, 'banner_image_url', url)
              }
              uploadAction={() =>
                formik.setFieldTouched('banner_image_url', null)
              }
              deleteAction={() =>
                handlerFormikFieldChange(formik, 'banner_image_url', null)
              }
              width={150}
              height={150}
              disabled={otherInputDisabled}
              videoUploadFlag={video_upload_flag}
            />
          </div>

          <TextBox
            formik={formik}
            preLabel="ガチャ名"
            fieldName="gacha_name"
            width={'100%'}
            isRequired
            disabled={otherInputDisabled}
          />
          <div className="gacha-content">
            <span className="error">
              {formik.touched.gacha_content && formik.errors.gacha_content}
            </span>
            <span
              className="cus-input-line input-textarea"
              style={new_contents_flag !== 0 ? { height: 'unset' } : {}}
            >
              <span className="pre-label">
                <span
                  className={classNames('label-required', {
                    'label-disabled': otherInputDisabled,
                  })}
                >
                  ガチャ説明
                </span>
                {new_contents_flag === 0 && (
                  <ContentsTooltip notificationTip={true} />
                )}
              </span>
              {new_contents_flag !== 0 ? (
                <div style={{ width: '100%' }}>
                  <RichTextEditor
                    readOnly={otherInputDisabled}
                    editorState={formik.values.gacha_rich_content}
                    error={
                      formik.touched.gacha_rich_content &&
                      formik.errors.gacha_rich_content
                    }
                    onChange={state =>
                      formik.setFieldValue('gacha_rich_content', state)
                    }
                    setTouched={() =>
                      !formik.touched.gacha_rich_content &&
                      formik.setFieldTouched('gacha_rich_content', true)
                    }
                    placeholder={'ガチャ説明を入力してください'}
                  />
                </div>
              ) : (
                <textarea
                  placeholder="ガチャ説明を入力してください"
                  spellCheck={false}
                  autoComplete={'false'}
                  disabled={otherInputDisabled}
                  style={{ height: 100 }}
                  {...formik.getFieldProps('gacha_content')}
                />
              )}
            </span>
          </div>
          {new_contents_flag === 0 && (
            <ImagesUploader
              label={'画像'}
              formik={formik}
              fieldName={'image_url'}
              isSpanLabel={true}
              disabled={otherInputDisabled}
              videoUploadFlag={video_upload_flag}
            />
          )}
          <SelectBox
            formik={formik}
            preLabel="定型文"
            fieldName="template"
            placeholder="テンプレートを選択"
            options={templateList}
            disabled={otherInputDisabled}
            width={400}
            extraOnChange={(_, v) =>
              handlerFormikFieldChange(
                formik,
                'fixed_phrase',
                templateList.find(e => e.value === v)?.template_text
              )
            }
          />
          <span
            className="cus-input-line input-textarea"
            style={{ marginTop: '5px' }}
          >
            <label />
            <textarea
              placeholder=""
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.fixed_phrase}
              disabled={otherInputDisabled}
              onChange={e =>
                handlerFormikFieldChange(formik, 'fixed_phrase', e.target.value)
              }
            />
          </span>
          <DateTimeRangePicker
            formik={formik}
            preLabel={'キャンペーン表示期間'}
            fromFieldName={'campaign_start_date'}
            fromHourFieldName={'campaign_start_hour'}
            fromMinuteFieldName={'campaign_start_minute'}
            toFieldName={'campaign_end_date'}
            toHourFieldName={'campaign_end_hour'}
            toMinuteFieldName={'campaign_end_minute'}
            fromUneditable={isCopy ? !isCopy : isUpdate}
            isRequired
            width={'100%'}
            disabled={otherInputDisabled}
          />
          <DateTimeRangePicker
            formik={formik}
            preLabel={'ガチャ実施期間'}
            fromFieldName={'gacha_start_date'}
            fromHourFieldName={'gacha_start_hour'}
            fromMinuteFieldName={'gacha_start_minute'}
            toFieldName={'gacha_end_date'}
            toHourFieldName={'gacha_end_hour'}
            toMinuteFieldName={'gacha_end_minute'}
            fromUneditable={isCopy ? !isCopy : isUpdate}
            toUneditable={isCopy ? !isCopy : isUpdate}
            isRequired
            width={'100%'}
            disabled={otherInputDisabled}
          />
          <span className="push-area">
            <span
              className={classNames('pre-label label-required', {
                'label-disabled': otherInputDisabled,
              })}
            >
              プッシュ通知
            </span>
            {!isCopy && isUpdate ? (
              <div className="push-list">
                <div className="radio-area">
                  <span
                    className={classNames('pre-label', {
                      'label-disabled': otherInputDisabled,
                    })}
                  >
                    キャンペーン表示開始
                  </span>
                  <span className="font-sm">
                    {getOptionLabel(flagOptions, campaign_notice_flag)}
                  </span>
                </div>
                <div className="radio-area end">
                  <span
                    className={classNames('pre-label', {
                      'label-disabled': otherInputDisabled,
                    })}
                  >
                    ガチャ実施開始
                  </span>
                  <span className="font-sm">
                    {getOptionLabel(flagOptions, gacha_notice_flag)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="push-list edit">
                <div className="radio-area">
                  <TcRadio
                    formik={formik}
                    label={'キャンペーン表示開始'}
                    isRadio={true}
                    fieldName="campaign_notice_flag"
                    width={150}
                    options={flagOptions}
                    disabled={otherInputDisabled}
                    labelDisabled={otherInputDisabled}
                    onChange={v =>
                      handlerFormikFieldChange(
                        formik,
                        'campaign_notice_flag',
                        v
                      )
                    }
                  />
                </div>
                <div className="radio-area end">
                  <TcRadio
                    disabled={otherInputDisabled}
                    labelDisabled={otherInputDisabled}
                    formik={formik}
                    label={'ガチャ実施開始'}
                    isRadio={true}
                    fieldName="gacha_notice_flag"
                    width={150}
                    options={flagOptions}
                    onChange={v =>
                      handlerFormikFieldChange(formik, 'gacha_notice_flag', v)
                    }
                  />
                </div>
              </div>
            )}
          </span>

          {['1', '4'].includes(trigger) && (
            <div className={'gacha-continuous-startup-area'}>
              <div className={'gacha-label-area'}>
                <div className={'gacha-label'}>連続起動日数</div>
                {!isCopy && isUpdate ? (
                  <></>
                ) : (
                  <Tooltip
                    className="tc-tooltip"
                    overlayInnerStyle={{ width: '375px' }}
                    title={
                      <label
                        style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}
                      >
                        初回ログイン時とアプリ起動時のガチャが同時に実行されることを防ぐため、連続起動日数は2日以上に限定してます。
                      </label>
                    }
                  >
                    <Icons.IconHelpCircle className="icon-help" />
                  </Tooltip>
                )}
              </div>
              <CustomNumberPicker
                formik={formik}
                fieldName={'consecutive_startup_days'}
                placeholder={'00'}
                width={!isCopy && isUpdate ? 140 : 162}
                from={2}
                to={31}
                uneditable={!isCopy && isUpdate}
                suffix={'日'}
                isRequired
              />
            </div>
          )}

          {['2', '5'].includes(trigger) && (
            <span className="gacha-type-area" style={{ marginTop: 30 }}>
              <TcRadio
                formik={formik}
                label={'チェックインルール'}
                fieldName="check_in_rule"
                width={280}
                options={checkinRuleOptions}
                required={true}
                isRadio={true}
              />
            </span>
          )}

          {!isCopy && isUpdate ? (
            <div className="show-area type">
              <span
                className={classNames('pre-label label-required', {
                  'label-disabled': otherInputDisabled,
                })}
              >
                当選形式
              </span>
              <span className="font-sm">
                {getOptionLabel(winningOptions, lottery_method)}
              </span>
            </div>
          ) : (
            <span className="gacha-type-area" style={{ marginTop: 30 }}>
              <TcRadio
                disabled={otherInputDisabled}
                labelDisabled={otherInputDisabled}
                formik={formik}
                label={'当選形式'}
                isRadio={true}
                fieldName="lottery_method"
                width={150}
                required={true}
                options={winningOptions}
                onChange={v =>
                  handlerFormikFieldChange(formik, 'lottery_method', v)
                }
              />
            </span>
          )}

          <ShopGiftListElement
            formik={formik}
            trigger={trigger}
            isCopy={isCopy}
            isUpdate={isUpdate}
          />

          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={!isCopy && isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GachaModal;
